import { createContext, ReactNode } from 'react'
import { useGetProfileQuery } from '../store/api'

type AuthenticationContextData = {
  isAuthenticated: boolean
}

const AuthenticationContext = createContext<AuthenticationContextData>({
  isAuthenticated: false,
})

type AuthProviderProps = {
  children: ReactNode
}

export const AuthenticationProvider = ({ children }: AuthProviderProps) => {
  const { data: profile, isLoading } = useGetProfileQuery()

  return (
    <AuthenticationContext.Provider
      value={{
        isAuthenticated: Boolean(profile),
      }}
    >
      {!isLoading && children}
    </AuthenticationContext.Provider>
  )
}
