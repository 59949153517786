import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Heroes } from './Pages/Heroes';
import { GuideList } from './Pages/GuideList';
import { GuideDescription } from './Pages/GuideDescription';
import { Page404 } from './Pages/Page404';
import { Layout } from './components/Layout';
import { SignUp } from './Pages/SignUp';
import { Login } from './Pages/Login';
import { Items } from './Pages/Items';
import { AuthenticationProvider } from './providers/AuthenticationProvider';
import { Provider } from 'react-redux';
import { store } from './store';

function App() {

  return (
    <Provider store={store}>
      <AuthenticationProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Navigate to="/home" />} />
              <Route path="/home" element={<Heroes />} />
              <Route path="/heroes" element={<Heroes />} />
              <Route path='/heroes/:id' element={<GuideList />} />
              <Route path='/heroes/:heroId/guides/:guideId' element={<GuideDescription />} />
              <Route path='/sign-up' element={<SignUp />} />
              <Route path='/login' element={<Login />} />
              <Route path="*" element={<Page404 />} />
              <Route path='/items' element={<Items />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AuthenticationProvider>
    </Provider>
  );
}

export default App;
