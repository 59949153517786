import { FC } from "react"
import styles from './AddGuideButton.module.scss'

type AddGuideButtonProps = {
  onClick: () => void
}

export const AddGuideButton: FC<AddGuideButtonProps> = ({ onClick }) => {
  return (
    <button onClick={onClick} className={styles.button}>+</button>
  )
}