import { FC } from "react";
import { useGetItemsQuery } from "../../store/api";
import styles from './Items.module.scss'
import { Tooltip } from "@mui/material";

type ItemDescriptionProps = {
  name: string
  description: string
}

const ItemDescription: FC<ItemDescriptionProps> = ({ name, description }) => (
  <div>
    <h4>{name}</h4>
    <p>{description}</p>
  </div>
);

export const Items: FC = () => {
  const { data: itemSections = [] } = useGetItemsQuery()
  return (
    <div className={styles.container}>
      {itemSections.map((section) => {
        return (
          <div className={styles.section}>
            <Tooltip title={section.sectionName}><img src={section.img} className={styles.sectionImg} /></Tooltip>
            <div className={styles.items}>
              {section.items.map((item) => {
                return (
                  <Tooltip title={<ItemDescription name={item.itemName} description={item.itemDescription} />}><img src={item.img} className={styles.itemImg} /></Tooltip>
                )
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}