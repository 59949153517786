import React from 'react'
import { Outlet } from 'react-router-dom'
import { Header } from '../Header'
import styles from '../Layout/Layout.module.scss'

export const Layout: React.FC = () => {
  return (
    <div className={styles.layout}>
      <Header />
      <div className={styles.scrollingContainer}>
        <Outlet />
      </div>
    </div>
  )
}
