import { FC } from "react"
import styles from './HeaderLink.module.scss'
import cn from 'classnames'
import { Link, useLocation } from "react-router-dom"

type HeaderLinkProps = {
  to: string
  label: string
}

export const HeaderLink: FC<HeaderLinkProps> = ({ to, label }) => {

  const location = useLocation()
  const isActive = location.pathname === to

  return (
    <Link to={to} className={cn(styles.link, isActive && styles.activeLink)}>
      <h2 className={styles.label}>{label}</h2>
      <div className={styles.line} />
    </Link>
  )
}