import { FC } from "react"
import { Link } from "react-router-dom"
import styles from './Page404.module.scss'

export const Page404: FC = () => {
    return (
        <div className={styles.page}>
            <h1>
                The page is not found (404)
            </h1>
            <h1>
                <Link to='/' className={styles.link}>
                    Click here to return to main page
                </Link>
            </h1>
        </div>
    )
}