import { configureStore } from "@reduxjs/toolkit/react";
import { setupListeners } from "@reduxjs/toolkit/query";
import { dotaApi } from "./api";

export const store = configureStore({
    reducer: {
        [dotaApi.reducerPath]: dotaApi.reducer
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(dotaApi.middleware)
})

setupListeners(store.dispatch)