import { FC } from "react";
import styles from './Button.module.scss'

export type ButtonProps = {
  label?: string
  onClick?: () => void
}

export const Button: FC<ButtonProps> = ({ label, onClick }) => {
  return (
    <button className={styles.button} onClick={onClick}>
      {label}
    </button >
  )
}