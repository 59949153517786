import { FC } from "react";
import styles from './Header.module.scss'
import { useNavigate } from "react-router-dom";
import { HeaderLink } from "../HeaderLink";
import { Link } from "react-router-dom";
import { dotaApi, useGetProfileQuery, useLogOutMutation } from "../../store/api";
import { useDispatch } from "react-redux";

export const Header: FC = () => {
  const navigate = useNavigate()
  const { data: loggedProfile } = useGetProfileQuery()
  const [logOut] = useLogOutMutation()
  const dispatch = useDispatch()

  const onLogOut = async () => {
    await logOut()
    localStorage.removeItem('token')
    dispatch(dotaApi.util.invalidateTags(['Profile']))
  }

  return (
    <div className={styles.header}>
      <div className={styles.leftContainer}>
        <Link to={'/home'} className={styles.logo}>
          <img onClick={() => navigate('/')} src='/images/logo.png' width={60} height={60} />
          <h1>
            DOTA GUIDES
          </h1>
        </Link>
        <HeaderLink label="HEROES" to='/heroes' />
        <HeaderLink label="ITEMS" to='/items' />
      </div>
      {loggedProfile ?
        (<div className={styles.rightContainer}>
          <div className={styles.login} onClick={() => onLogOut()}>
            <h2>
              Sign Out
            </h2>
          </div>
          <div className={styles.signUp}>
            <h2 >
              {loggedProfile.username}
            </h2>
          </div>
        </div>)
        :
        (<div className={styles.rightContainer}>
          <Link to={'/login'} className={styles.login}>
            <h2>LOGIN</h2>
          </Link>
          <Link to={'/sign-up'} className={styles.signUp}>
            <h2>SIGN UP</h2>
          </Link>
        </div>)}
    </div>
  )
}