import { useParams } from 'react-router-dom';
import styles from './GuideList.module.scss'
import { useGetHeroDetailsQuery, useGetProfileQuery } from '../../store/api';
import { Link } from 'react-router-dom';
import { AddGuideButton } from './components/AddGuideButton';
import { useState } from 'react';
import { AddGuideModal } from './components/AddGuideModal';

export const GuideList = () => {
    const { id } = useParams<{ id?: string }>()
    const [isOpen, setIsOpen] = useState(false)
    const idString = id ?? ''
    const { data: hero } = useGetHeroDetailsQuery(idString)
    const { data: loggedProfile } = useGetProfileQuery()
    if (hero)
        return (
            <div className={styles.page}>
                <AddGuideModal open={isOpen} onClose={() => setIsOpen(false)} heroName={hero.heroName} />
                <h1 className={styles.title}>{hero.heroName} guides</h1>
                <div className={styles.sections}>
                    <div className={styles.section}>
                        <h3 className={styles.subTitle}>Admin Guides</h3>
                        {loggedProfile?.username == 'admin' &&
                            <AddGuideButton onClick={() => setIsOpen(true)} />
                        }
                        <div className={styles.list}>
                            {hero.adminGuides.length > 0 ? hero.adminGuides.map((guide) => {
                                return (
                                    <div className={styles.guide}>
                                        <Link to={`guides/${guide.id}`} className={styles.link}>
                                            <h2>{guide.guideTitle}</h2>
                                        </Link>
                                    </div>
                                )
                            }) : <h3>There are no guides for this hero yet</h3>}
                        </div>
                    </div>
                    <div className={styles.section}>
                        {loggedProfile ? (<><h3 className={styles.subTitle}>Your Guides</h3>
                            <div className={styles.list}>
                                {!!hero.userGuides && hero?.userGuides?.length > 0 ? hero.userGuides.map((guide) => {
                                    return (
                                        <div className={styles.guide}>
                                            <Link to={`guides/${guide.id}`} className={styles.link}>
                                                <h2>{guide.guideTitle}</h2>
                                            </Link>
                                        </div>
                                    )
                                }) : <h3>You don't have any custom guides for this hero</h3>}
                            </div></>)
                            :
                            <h3 className={styles.subTitle}>To see custom guides you need to be <Link to={'/login'}>authorized</Link></h3>}
                    </div>
                </div>
            </div>
        );
    else
        return (<></>)
};