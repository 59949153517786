import { Dialog, DialogContent } from "@mui/material";
import { FC, useState } from "react";
import { TextField } from "../../../../components/TextField";
import { CreateGuideBody } from "../../../../store/api/types/Guide.types";
import { useCreateGuideMutation, useGetProfileQuery } from "../../../../store/api";
import { Button } from "../../../../components/Button";
import { SnackBar } from "../../../../components/Alert";
import styles from './AddGuideModal.module.scss'

type AddGuideModalProps = {
  open: boolean
  onClose: () => void
  heroName: string
}

export const AddGuideModal: FC<AddGuideModalProps> = ({ open, onClose, heroName }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [label, setLabel] = useState('')
  const [createGuide] = useCreateGuideMutation()
  const [guide, setGuide] = useState<CreateGuideBody>({
    hero: heroName,
    guideTitle: "string",
    guideDescription: "string"
  });

  const handleChange = (field: keyof CreateGuideBody, value: string) => {
    setGuide(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleSubmit = async () => {
    const responce = await createGuide(guide)
    if (!responce.error)
      onClose()
    else {
      //@ts-ignore
      if (typeof response.error.data === 'object') {
        //@ts-ignore
        const errorMessages = Object.entries(response.error.data)
          .map(([key, value]) => `${key}: ${value}`)
          .join('<br />');
        setLabel(errorMessages);
      } else {
        //@ts-ignore
        setLabel(response.error.data);
      }
      setIsOpen(true);
    }
  }

  return (
    <Dialog open={open} onClose={onClose} >
      <SnackBar label={label} isOpen={isOpen} onClose={() => setIsOpen(false)} />
      <DialogContent className={styles.modal}>
        <TextField
          label="Guide Title"
          onChange={(value) => handleChange('guideTitle', value)}
        />
        <TextField
          label="Guide Description"
          onChange={(value) => handleChange('guideDescription', value)}
        />
        <Button onClick={handleSubmit} label="Create admin guide" />
        <Button onClick={onClose} label="Cancel" />
      </DialogContent>
    </Dialog>
  )
}